import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useSessionPoll = (joinId: string, interval: number = 2000) => {
  const [sessionStarted, setSessionStarted] = useState(false);
  const [hostUserId, setHostUserId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSessionStatus = async () => {
      try {
        const response = await fetch(`https://nicky.fly.dev/session?id=${joinId}`);
        if (response.ok) {
          const data = await response.json();
          if (data.call_id) {
            setHostUserId(data.host_user_id + '-client');
            setSessionStarted(true);
            navigate(`/player/${joinId}`, { state: { hostUserId: data.host_user_id + '-client' } });
          }
        }
      } catch (error) {
        console.error('Error checking session status:', error);
      }
    };

    const intervalId = setInterval(checkSessionStatus, interval);
    return () => clearInterval(intervalId);
  }, [joinId, interval, navigate]);

  return { sessionStarted, hostUserId };
};

export default useSessionPoll;
