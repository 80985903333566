interface StreamClientCredentials {
  client_id: string;
  user_id: string;
  token: string;
}

export const getStreamCredentials = async (userId: string): Promise<StreamClientCredentials> => {
  const apiUrl = 'https://nicky.fly.dev/auth';

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user_id: userId }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};
