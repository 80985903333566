import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { useState } from 'react';
import useSessionPoll from '../hooks/useSessionPoll';
import { Box, Badge, Image, Text, VStack, Heading } from '@chakra-ui/react';
import logo from '../assets/logo.png'; // Path to your logo image

const generateJoinId = () => {
  return Math.floor(100000000 + Math.random() * 900000000).toString();
};

const getQRCodeLink = (joinId: string) => {
  return `https://flect.app/join?id=${joinId}`;
};

export const Join = () => {
  const navigate = useNavigate();
  const [joinId] = useState(generateJoinId());
  const { sessionStarted, hostUserId } = useSessionPoll(joinId);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <Image src={logo} alt="Logo" width="150px" mb={8} />
      <Heading as="h1" size="lg" mb={6} textAlign="center" color="white">
        Scan the code below to start casting
      </Heading>
      <VStack spacing={6} textAlign="center" width="100%" maxWidth="400px">
        <Box>
          <Badge
            bg="white"
            color="black"
            fontWeight="bold"
            px={3}
            py={1}
            borderRadius="md"
            mb={2}
          >
            STEP 1
          </Badge>
          <Text fontSize="md" color="white">Open the flect app on your phone</Text>
        </Box>
        <Box>
          <Badge
            bg="white"
            color="black"
            fontWeight="bold"
            px={3}
            py={1}
            borderRadius="md"
            mb={2}
          >
            STEP 2
          </Badge>
          <Text fontSize="md" color="white">Scan the QR code</Text>
          <Box display="inline-block" mt={4} p={2} bg="white" borderRadius="lg">
            <QRCode value={getQRCodeLink(joinId)} size={128} />
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};
