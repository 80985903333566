import { useState, useEffect } from 'react';
import { StreamVideoClient, User, Call } from '@stream-io/video-react-sdk';
import { getStreamCredentials } from '../api';

interface StreamClient {
  client: StreamVideoClient | null;
  call: Call | null;
}

const useStreamClient = (userId: string | null, joinId?: string): StreamClient => {
  const [client, setClient] = useState<StreamVideoClient | null>(null);
  const [call, setCall] = useState<Call | null>(null);

  useEffect(() => {
    if (!joinId || !userId) return;

    let isMounted = true;

    const initializeClient = async () => {
      try {
        const data = await getStreamCredentials(userId);

        if (!isMounted) return;

        const user: User = { id: data.user_id };
        const apiKey = data.client_id;
        const token = data.token;

        const client = new StreamVideoClient({ apiKey: apiKey, token: token, user: user});
        setClient(client);
      } catch (error) {
        console.error('Failed to initialize client:', error);
      }
    };

    initializeClient();

    return () => {
      isMounted = false;
    };
  }, [joinId, userId]);

  useEffect(() => {
    if (client && joinId) {
      const call = client.call('default', joinId);
      call.join({ create: true });
      setCall(call);
    }
  }, [client, joinId]);

  return { client, call };
};

export default useStreamClient;