import React, { useState, useEffect } from 'react'
import {
  hasScreenShare,
  useCall,
  useCallStateHooks,
  ParticipantView,
  StreamVideoParticipant,
} from '@stream-io/video-react-sdk'
import './SpeakerView.css'
import { Box, Heading, Text } from '@chakra-ui/react'
import CustomParticipantViewUI from './CustomParticipantViewUI' // Path to your custom component
import CustomVideoPlaceholder from './CustomVideoPlaceholder' // Path to your custom component

function SpeakerView() {
  const call = useCall()
  const { useParticipants } = useCallStateHooks()
  const participants = useParticipants()
  const screenShareParticipant = participants.find(hasScreenShare)
  const [lastScreenShareParticipant, setLastScreenShareParticipant] = useState<StreamVideoParticipant | null>(null)

  useEffect(() => {
    if (screenShareParticipant) {
      setLastScreenShareParticipant(screenShareParticipant)
    }
  }, [screenShareParticipant])

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" width="100%">
      {call && lastScreenShareParticipant ? (
        <ParticipantView
          participant={lastScreenShareParticipant}
          trackType="screenShareTrack"
          ParticipantViewUI={<CustomParticipantViewUI />}
          VideoPlaceholder={CustomVideoPlaceholder}
        />
      ) : (
        <Box textAlign="center">
          <Heading as="h1" size="lg" mb={4} color="white">
            Ready to Cast
          </Heading>
          <Text fontSize="md" color="white">
            Start casting on the app to see your screen here
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default SpeakerView
