import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  StreamCall,
  StreamTheme,
  StreamVideo,
} from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import useStreamClient from '../hooks/useStreamClient';
import SpeakerView from './SpeakerView';
import { Box, Spinner } from '@chakra-ui/react';
import { Header } from './Header';

export const Player = () => {
  const { joinId } = useParams<{ joinId?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const hostUserId = location.state?.hostUserId;

  useEffect(() => {
    if (!joinId || !hostUserId) {
      navigate('/');
    }
  }, [joinId, hostUserId, navigate]);

  const { client, call } = useStreamClient(hostUserId, joinId);

  if (!client || !call) {
    return (
      <Box display="flex" flexDirection="column" minHeight="100vh" bg="#221F1F">
        <Header />
        <Box flex="1" display="flex" alignItems="center" justifyContent="center">
          <Spinner color="primary" />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" bg="#221F1F">
      <Header />
      <Box flex="1" display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="calc(100vh - 64px)">
        <StreamVideo client={client}>
          <StreamCall call={call}>
            <StreamTheme>
              <SpeakerView />
            </StreamTheme>
          </StreamCall>
        </StreamVideo>
      </Box>
    </Box>
  );
};
