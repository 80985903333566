import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Join } from './components/Join';
import { Player } from './components/Player';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        backgroundColor: '#221F1F',
        color: '#fff',
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
    },
  },
  colors: {
    primary: '#E50914',
  },
});

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Join />} />
          <Route path="/player/:joinId" element={<Player />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
