// src/components/Header.tsx

import { Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Path to your logo image

export const Header = () => {
  return (
    <Box as="header" width="100%" padding="4" bg="#221F1F">
      <Link to="/">
        <Image src={logo} alt="Logo" width="100px" />
      </Link>
    </Box>
  );
};
