import React, { useRef, useEffect } from 'react'
import { useParticipantViewContext } from '@stream-io/video-react-sdk'
import { useFullscreen } from '../hooks/useFullscreen' // Path to your custom hook
import { FaExpand, FaCompress } from 'react-icons/fa'

function CustomParticipantViewUI() {
  const { participantViewElement } = useParticipantViewContext()
  const elementRef = useRef(participantViewElement as HTMLDivElement)
  const { isFullscreen, toggleFullscreen } = useFullscreen(elementRef)

  useEffect(() => {
    if (participantViewElement) {
      elementRef.current = participantViewElement as HTMLDivElement
    }
  }, [participantViewElement])

  return (
    <button
      style={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1000, background: 'none', border: 'none', color: 'white' }}
      onClick={toggleFullscreen}
    >
      {isFullscreen ? <FaCompress size={24} /> : <FaExpand size={24} />}
    </button>
  )
}

export default CustomParticipantViewUI
